<template>
  <div class="hamburger-menu">
    <div class="hamburger-menu__container" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <button class="hamburger-menu__button">
        <span class="material-symbols-rounded header-content__icon">menu</span>
      </button>
      <span>menu</span>
    </div>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <img class="header-content__img" src="../assets/images/logoblanco.png" alt="jazz247" />
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.849121C5.37273 0.849121 0 6.22185 0 12.8491C0 19.4764 5.37273 24.8491 12 24.8491C18.6273 24.8491 24 19.4764 24 12.8491C24 6.22185 18.6273 0.849121 12 0.849121ZM16.044 10.3477C16.2427 10.1419 16.3527 9.86635 16.3502 9.58032C16.3477 9.29429 16.233 9.02067 16.0307 8.81841C15.8284 8.61615 15.5548 8.50142 15.2688 8.49893C14.9828 8.49645 14.7072 8.6064 14.5015 8.80512L12 11.3066L9.49855 8.80512C9.39791 8.70093 9.27754 8.61782 9.14444 8.56065C9.01135 8.50347 8.8682 8.47338 8.72335 8.47212C8.5785 8.47086 8.43485 8.49846 8.30078 8.55332C8.16671 8.60817 8.04491 8.68917 7.94248 8.7916C7.84005 8.89403 7.75905 9.01583 7.7042 9.1499C7.64934 9.28397 7.62174 9.42762 7.623 9.57247C7.62426 9.71732 7.65435 9.86047 7.71153 9.99356C7.7687 10.1267 7.85181 10.247 7.956 10.3477L10.4575 12.8491L7.956 15.3506C7.85181 15.4512 7.7687 15.5716 7.71153 15.7047C7.65435 15.8378 7.62426 15.9809 7.623 16.1258C7.62174 16.2706 7.64934 16.4143 7.7042 16.5483C7.75905 16.6824 7.84005 16.8042 7.94248 16.9066C8.04491 17.0091 8.16671 17.0901 8.30078 17.1449C8.43485 17.1998 8.5785 17.2274 8.72335 17.2261C8.8682 17.2249 9.01135 17.1948 9.14444 17.1376C9.27754 17.0804 9.39791 16.9973 9.49855 16.8931L12 14.3917L14.5015 16.8931C14.7072 17.0918 14.9828 17.2018 15.2688 17.1993C15.5548 17.1968 15.8284 17.0821 16.0307 16.8798C16.233 16.6776 16.3477 16.404 16.3502 16.1179C16.3527 15.8319 16.2427 15.5563 16.044 15.3506L13.5425 12.8491L16.044 10.3477Z" fill="#5D76EB"/>
        </svg>
      </button>
      <li class="hamburger-menu__item" v-for="(route, index) in routes" :key="index">
        <router-link  @click="isActive = !isActive" class="uppercase-text" :to="route.route">{{ route.item }}</router-link>
      </li>
      <div class="container-lag">
              <div class="container-langs" v-if="this.$i18n.locale =='en'">
                <div class="lang" @click="changeLanguage('en')"> Eng </div>
                <div class="lang" @click="changeLanguage('es')"> Esp </div>
                <svg class="svg-line" height="70">
                  <line x1="0" y1="0" x2="0" y2="70" style="stroke:#5D77EB;stroke-width:5" />
                </svg>
              </div>
              <div class="container-langs" v-if="this.$i18n.locale =='es'">
                <div class="lang" @click="changeLanguage('es')"> Esp </div>
                <div class="lang" @click="changeLanguage('en')"> Eng </div>
                <svg class="svg-line" height="70">
                  <line x1="0" y1="0" x2="0" y2="70" style="stroke:#5D77EB;stroke-width:5" />
                </svg>
              </div>
              
              <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
              <path d="M1 1L8 8L1 15" stroke="#5D77EB" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <!-- Resto del contenido de tu componente -->
      </div>
    </ul>
  </div>
</template>

<script>
import { createRouter, createWebHistory } from 'vue-router';
import { reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  name: "MenuComponent",
  methods: {
      changeLanguage(locale) {

        if(this.$i18n.locale!= locale){
          this.$i18n.locale = locale;
          const t = this.$t;
          
          const translatedRoutes = this.getTranslatedRoutes(t);
          const router = createRouter({
            history: createWebHistory(),
            routes: translatedRoutes
          });
          this.$router.matcher = router.matcher;
          const currentRoute = this.$router.currentRoute.value;

          if (currentRoute.path !== '/') {
            const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
            this.$router.push(translatedPath);
          }
        }
        
      },
      getTranslatedRoutes(t) {
        
          const routes = this.$router.options.routes.map((route) => ({
            ...route,
            path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
          }));

          return routes;
        },
        detectLang(pathName){
          console.log(pathName);
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else{
            console.log("default")
          }
      }
    },
  setup() {
    const isActive = ref(false);
    const { t, locale }  = useI18n();
    const routes = reactive([
    {
            item       :  t("routeshead.0.name"),
            route      :  t('routeshead.0.route')
          },
          {
            item       :  t('routeshead.1.name'),
            route      :  t('routeshead.1.route')
          },
          {
            item       :  t('routeshead.2.name'),
            route      :  t('routeshead.2.route')
          },
          {
            item       :  t('routeshead.3.name'),
            route      :  t('routeshead.3.route')
          },
          {
            item       :  t('routeshead.4.name'),
            route      :  t('routeshead.4.route')
          }
    ]);

      // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        routes.forEach((routes, index) => {
          routes.item = t(`routeshead.${index}.name`);
          routes.route = t(`routeshead.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });

    return {
      isActive,
      routes
    };
  },
};
</script>